/* You can add global styles to this file, and also import other style files */
.error_msg{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.mtb-2{
    margin:9px 20px;
}

.btn-space button{
    margin-right:8px;
}

a{
    text-decoration: none;
}

